export const faq = [
	{
		question: `What is methane and why is it important in the context of natural gas production?`,
		answer: `
			<p>Methane (CH<sub>4</sub>) is a key component of natural gas and a potent greenhouse gas.  Methane in the atmosphere is over 82.5 times more potent than carbon dioxide over a 20-year time horizon. Reducing methane emissions from oil and natural gas production is important to protect the environment and can be done by monitoring and fixing leaks, capturing released methane, and using more efficient technology.</p>`,
	},

	{
		question: `How does methane leak from natural gas production?`,
		answer: `
			<p>Methane can leak from natural gas production at various stages of the process, including:</p>

			<p class='inset'>1. Drilling and well completion: During the drilling and completion of natural gas wells, methane can escape through cracks in the well casing or from poorly cemented areas around the well.</p>

			<p class='inset'>2. Production and processing: Methane can escape during the production and processing of natural gas when equipment such as compressors, pumps, and valves malfunction or are not properly maintained. Methane can also escape from pipelines and storage facilities due to leaks or other issues.</p>

			<p class='inset'>3. Venting and flaring: Natural gas producers may also intentionally release methane through venting or flaring. Venting involves releasing methane directly into the atmosphere, while flaring involves burning off the methane before it is released. While flaring can reduce the amount of methane released into the atmosphere, it still releases carbon dioxide, a greenhouse gas, into the atmosphere.</p>

			<p>Overall, methane leaks from natural gas production can contribute significantly to greenhouse gas emissions, and reducing these leaks is an important part of addressing climate change.</p>`,
	},

	{
		question: `How does the production of natural gas compare to other energy sources in terms of methane emissions?`,
		answer: `
			<p>Natural gas production is a major source of methane emissions, contributing about 32 percent according to the EPA. Coal mining is another source, responsible for 6 percent of methane emissions. However, coal power plants emit more carbon dioxide, making their overall impact greater. Leaks in natural gas production and transportation contribute to its methane emissions, while coal mining emissions come mainly from coal bed methane. Reducing methane emissions from natural gas can be achieved through monitoring and fixing leaks, capturing released methane, and using more efficient technology.</p>
			
			<figure>
				<h2>2020 US Methane Emissions, By Source</h2>

				<img src='./client/media/figures/emissions.svg'/>
			</figure>`,
	},

	{
		question: `How does the US oil and gas industry methane leakage compare to other countries?`,
		answer: `
			<p>The US oil and gas industry is a large emitter of methane, but its leakage rates are about average when excluding the heaviest emitters like Turkmenistan and Venezuela.</p>
			
			<figure>
				<h2>Upstream Oil/Gas Methane Intensities in 2019</h2>

				<img src='./client/media/figures/methane_by_country.svg'/>

				<div class='caption'>
					<p>Figure 2. Methane leakage rates for different countries</p>
					<p>Source: Z.Chen et al. (#).</p>
					<p>Assumes a methane content in gas 92%; The Oil and Gas Climate initiative (OGCI) 2025 upstream methane intensity target of 0.2%.</p>
				</div>
			</figure>`,
	},

	{
		question: `What are the most effective technologies for capturing and reducing methane emissions in natural gas and coal production?`,
		answer: `
			<p>There are several technologies that have proven effective for capturing and reducing methane emissions in natural gas and coal production. Some of these include capture and utilization of methane as a fuel source or for other purposes, leak detection and repair to minimize emissions, green completions to redirect gas for use or sale, enhanced recovery methods to increase production and reduce emissions, and ventilation systems to control air flow and reduce methane buildup in mines. The most effective approach will depend on a variety of factors, including the specific site and circumstances. It's important to consider a variety of options and implement the most effective combination of technologies.</p>`,
	},

	{
		question: `How is the monitoring of methane emissions in the natural gas production process performed?`,
		answer: `
			<p>Monitoring of methane emissions in the natural gas production process is performed through a combination of direct measurement and estimation techniques. Direct measurement techniques include on-site monitoring of emissions from well sites, production facilities, and transmission pipelines. Estimation techniques use mathematical models and engineering estimates to estimate emissions from sources that are not directly measured. Both direct measurement and estimation techniques are important for developing an accurate understanding of methane emissions from the natural gas industry.</p>`,
	},

	{
		question: `What are the economic costs associated with reducing methane emissions in the natural gas production process?`,
		answer: `
			<p>Reducing methane emissions in the natural gas production process can have high costs — though per IEA estimates, as many as 45 percent of abatement opportunities are profitable given 2017-2021 natural gas prices.  Costs include things like leak detection and repair programs, upgrading or replacing equipment, and implementing best practices like reducing flaring and venting.  For facilities that exceed the &ldquo;waste emissions threshold&rdquo; established by the Inflation Reduction Act, costs can add up even more quickly.  By 2025, facilities will be charged $1,200 per metric ton of methane exceeding the emissions threshold. Despite the upfront costs, reducing emissions can lead to environmental and economic benefits, such as addressing climate change and improving efficiency and profitability. Financial incentives may also be provided by regulations and industry standards for methane emissions.</p>`,
	},

	{
		question: `What is the role of the oil and gas industry in reducing methane emissions?`,
		answer: `
			<p>The oil and gas industry plays a crucial role in reducing methane emissions. Methane is a potent greenhouse gas that contributes to global warming, and reducing its emissions is important for mitigating its impact on the environment. The oil and gas industry, being one of the largest sources of methane emissions globally, has a significant responsibility to reduce these emissions. This can be achieved through a variety of measures, including monitoring and repairing leaks in the production and transportation infrastructure, capturing methane before it is released into the atmosphere, and using more efficient technologies in the production process.</p>`,
	},
];
